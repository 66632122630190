@import "src/styles/variables/index";
@import "src/styles/mixins/index";
@import "src/styles/fonts/poppins";

$slick-font-family: "Inter";

@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";

.th-landing {
  &,
  * {
    font-family: Poppins, sans-serif;
  }

  background: white;
  overflow: hidden;

  .th-header-back {
    background: black;
    height: 60px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .th-title {
    color: white;
    font-size: 58px;
    font-weight: 300;
    line-height: 1.14;

    strong {
      color: #57ee47;
    }
  }

  strong {
    font-weight: 600;
  }

  .th-arrow-icon {
    border: 1.5px solid #6651f5;
    border-radius: 50%;
    color: #6651f5;
    height: 20px;
    width: 20px;
  }

  .th-section {
    @include th-flex-row;

    justify-content: center;
    position: relative;

    .th-section-content {
      @include th-flex-row;

      position: relative;
      z-index: 1;
    }

    .th-section-title {
      font-size: 44px;
      font-weight: 300;
      line-height: 1.09;
      margin-bottom: 30px;
    }

    .th-section-description {
      color: #595959;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.4;
    }

    .th-section-texts {
      display: flex;
      flex-direction: column;
    }
  }

  .th-hero-section {
    align-items: center;
    flex-direction: column;
    padding-top: $header-height;

    .th-section-content {
      height: 550px;
      width: 100% !important;
    }

    .th-section-background {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .th-section-texts {
      left: 230px;
      padding-bottom: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .th-title {
      margin-bottom: 40px;
      width: 420px;
    }
  }

  .th-explore-button {
    background: #20cb4c;
    border-radius: 30px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    height: 60px;
    width: 210px;

    .th-arrow-icon {
      border-color: white;
      color: white;
      margin-left: 12px;
    }
  }

  .th-section.th-main-value-prop-section {
    @include th-flex-column-center;

    background: #f7fcff;
    padding: 40px 10px;

    .th-section-title {
      display: block;
      font-size: 36px;
      font-weight: 300;
      letter-spacing: -0.73px;
      line-height: 1.09;
      margin: 0 auto;
      margin-bottom: 20px;
      max-width: 800px;
      text-align: center;
      width: 80%;
    }

    .th-section-description {
      color: #595959;
      font-size: 20px;
      font-weight: 300;
      letter-spacing: -0.33px;
      text-align: center;
      width: 60%;
    }
  }

  .th-section.th-explorer-section-test {
    background: black;
    margin-top: 18px;
    padding: 55px 0;

    .th-section-texts {
      width: 395px;

      .th-section-title {
        color: #57ee47;
        font-size: 44px;
        font-weight: 300;
        letter-spacing: -0.73px;
        margin-bottom: 16px;
      }

      .th-section-description {
        color: $brown-gray;
        font-size: 14px;
        letter-spacing: -0.23px;
        line-height: 1.43;

        @media (max-width: 1082px) {
          color: #eee;
        }
      }
    }

    .th-academy-button {
      margin-top: 16px;
    }

    .th-section-img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;

      @media (max-width: 640px) {
        left: 0;
      }

      @media (max-width: 1282px) {
        opacity: 0.2;
      }

      @media (max-width: $break-md) {
        opacity: 0.15;
      }
    }
  }

  .th-section.th-start-trial-section {
    @include th-flex-column-center;

    background: $primary;
    padding: 60px 45px;

    p {
      color: white;
      font-size: 34px;
      font-weight: 300;
      letter-spacing: -0.23px;
    }

    .th-get-started {
      border: 2px solid white;
      border-radius: 28px;
      color: white;
      font-size: 16px;
      font-weight: 600;
      height: 56px;
      margin-top: 30px;
      width: 160px;
    }
  }

  .th-section.th-analytics-section {
    @include th-flex-column-center;

    padding: 40px 10px;

    .th-section-title {
      font-size: 44px;
      font-weight: 300;
      letter-spacing: -0.73px;
      line-height: 1.09;
      margin-bottom: 20px;
      text-align: center;
    }

    .th-section-description {
      color: $brown-gray;
      font-size: 20px;
      font-weight: 300;
      letter-spacing: -0.33px;
      margin-bottom: 30px;
      text-align: center;
    }

    .th-analytics-metrics {
      background-color: #fff;
      border: solid 1px #eee;
      border-radius: 12px;
      box-shadow: 0 6px 24px 0 rgb(0 0 0 / 10%);
      column-count: 4;
      column-fill: balance;
      padding: 42px 110px;

      .th-category {
        margin-bottom: 24px;

        .th-category-title {
          @include th-flex-row-center;

          font-size: 14px;
          font-weight: 600;
          letter-spacing: -0.23px;

          svg {
            margin-right: 10px;
          }
        }

        .th-metric {
          color: #909090;
          cursor: pointer;
          font-size: 12px;
          letter-spacing: -0.11px;
          line-height: 2;
          margin-left: 30px;

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .th-section.th-company-section {
    flex-direction: column;
    padding: 40px 16px;
    width: 100%;

    .th-section-title {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }

    .th-section-content {
      .th-company-logos {
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;

        @include th-flex-row-center;

        .th-company {
          margin: 12px 8px;
          width: 80px;
        }
      }
    }
  }

  .th-section.th-testimonial-section {
    flex-direction: column;
    padding: 0 0 60px;

    .th-section-title {
      font-size: 38px;
      font-weight: 300;
      letter-spacing: -0.23px;
    }

    .th-section-content {
      display: block;
    }

    .th-testimonial-slider {
      margin: 0 -20px;

      .slick-prev::before,
      .slick-next::before {
        color: $primary;
      }

      button.slick-arrow.slick-prev {
        left: unset;
        right: 50px;
        top: -40px;
      }

      button.slick-arrow.slick-next {
        right: 20px;
        top: -40px;
      }

      .slick-dots {
        li.slick-active button::before {
          color: $primary;
          font-size: 40px;
          opacity: 1;
          transform: translateY(-1px);
        }

        li button::before {
          font-size: 32px;
        }
      }
    }

    .th-testimonial {
      max-width: 540px;
      padding: 0 20px;

      .th-text {
        color: #595959;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: -0.33px;
        line-height: 1.4;
        margin-bottom: 10px;
        padding-left: 10px;
        position: relative;

        &::before {
          color: $brown-gray;
          content: "“";
          font-size: 40px;
          left: -10px;
          position: absolute;
          top: -10px;
        }

        .th-end-mark {
          color: $brown-gray;
          font-size: 40px;
          vertical-align: text-top;
        }
      }

      .th-person {
        @include th-flex-row-center;

        margin-left: 8px;

        .th-avatar {
          border-radius: 50%;
          box-shadow: 0 0 8px rgb(0 0 0 / 10%);
          height: 73px;
          margin-right: 24px;
          overflow: hidden;
          width: 73px;
        }

        .th-name {
          color: #000;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: -0.27px;
          margin-bottom: 5px;
        }

        .th-license {
          color: #000;
          font-size: 14px;
          letter-spacing: 0.23px;
          line-height: 1.21;
          margin-top: 0.125rem;
          max-width: 210px;
          opacity: 0.5;
        }

        .th-job {
          color: #000;
          font-size: 14px;
          letter-spacing: 0.23px;
          line-height: 1.21;
          margin-top: 0.25rem;
          max-width: 210px;
          opacity: 0.5;
        }
      }
    }
  }

  .th-intro-video-container {
    left: 50%;
    position: absolute;
    width: 700px;
    z-index: 1;

    .th-intro-video-wrapper {
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .th-explore-link {
    color: #6651f5;
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    margin-top: 36px;

    svg {
      margin-left: 14px;
    }
  }

  .th-info-section {
    background: white;
    padding: 20px 0;

    .th-section-content {
      align-items: center;
      justify-content: center;
      padding: 0 65px;
    }

    .th-section-img {
      width: 670px;
    }

    .th-section-texts {
      margin-left: 101px;
      width: 446px;
    }
  }

  .th-carousel {
    background: white;
  }

  .th-prev-button {
    left: 20px;
  }

  .th-next-button {
    right: 20px;
  }

  .th-info-sliders .th-carousel {
    align-items: center;
  }

  .th-section.th-main-features-section {
    flex-direction: column;
    padding: 30px 0;

    .th-section-title {
      font-size: 44px;
      margin: 10px 0 30px;
      text-align: center;
    }

    .th-feature-block {
      @include th-flex-column;

      border-radius: 12px;
      flex-direction: column-reverse;
      height: 100%;
      justify-content: space-between;
      margin: auto;
      max-width: 384px;
      width: 100%;

      @media (min-width: $break-sm) {
        flex-direction: column;
      }

      .th-block-img {
        position: relative;
        width: 100%;
      }

      .th-block-texts {
        padding: 32px 30px;

        .th-block-title {
          font-size: 26px;
          font-weight: 300;
          letter-spacing: -0.43px;
          margin-bottom: 20px;
          text-align: center;
          width: 100%;
        }

        .th-block-description {
          color: #595959;
          font-size: 1px;
          font-weight: 300;
          letter-spacing: -0.27px;
          line-height: 1.5;
        }
      }
    }
  }

  .th-section.th-inman-testimonial-section {
    background: $primary;
    padding: 60px 0;

    .th-section-content {
      align-items: center;
      flex-direction: column;
      margin: 30px 0 0;
    }

    .th-testimonial-text {
      color: white;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.4;
      margin: 0 30px;
      position: relative;
      text-align: center;

      p {
        margin-bottom: 16px;
      }

      &::before {
        content: "“";
        font-size: 120px;
        left: -40px;
        opacity: 0.3;
        position: absolute;
        top: -60px;
      }

      &::after {
        bottom: -110px;
        content: "”";
        font-size: 120px;
        opacity: 0.3;
        position: absolute;
        right: -40px;
      }
    }

    .th-author {
      color: white;
      font-style: italic;
    }

    .th-logo {
      margin: 10px 0;
      width: 100px;

      img {
        width: 100%;
      }
    }
  }

  .th-sitemap-section {
    .th-section-content {
      @include th-flex-column;

      margin: 64px auto 32px;
    }

    .th-section-title {
      font-size: 21px !important;

      a {
        color: $primary;
        font-size: 16px;
        margin-left: 12px;
      }
    }

    .th-links {
      ul {
        columns: 4;
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: inline-block;
        padding: 3px;
        width: 100%;
      }

      ul.th-show-less li:nth-last-of-type(-n + 46) {
        display: none;
      }

      a {
        color: #666;
        font-size: 14px;
      }

      .th-show-links {
        color: $primary;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 4px;
      }
    }
  }

  @media (max-width: 1520px) {
    .th-intro-video-container {
      width: 600px;
    }
  }

  @media (max-width: $break-xl) {
    .th-hero-section {
      .th-section-content {
        height: 450px;
      }

      .th-title {
        font-size: 50px;
        width: 360px;
      }
    }

    .th-explore-button {
      border-radius: 25px;
      font-size: 16px;
      height: 50px;
      width: 180px;
    }

    .th-intro-video-container {
      width: 560px;
    }

    .th-info-section {
      padding: 40px 0 0;

      .th-section-description {
        font-size: 18px;
      }

      .th-section-content {
        padding: 0 10px;
      }

      .th-section-title {
        font-size: 36px;
        margin-bottom: 20px;
      }

      .th-section-img {
        width: 420px;
      }

      .th-section-texts {
        margin-left: 48px;
        width: 400px;
      }
    }

    .th-explore-link {
      margin-top: 30px;
    }

    .th-section.th-inman-testimonial-section {
      padding: 40px 0;

      .th-testimonial-text {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 1282px) {
    .th-hero-section {
      .th-section-texts {
        left: 152px;
      }
    }

    .th-intro-video-container {
      left: unset;
      right: 90px;
    }
  }

  @media (max-width: 1160px) {
    .th-hero-section {
      .th-section-texts {
        left: 102px;
      }
    }

    .th-intro-video-container {
      right: 50px;
      width: 520px;
    }
  }

  @media (max-width: 1060px) {
    .th-hero-section {
      .th-section-texts {
        left: 62px;
      }
    }

    .th-intro-video-container {
      right: 40px;
      width: 480px;
    }
  }

  @media (max-width: 960px) {
    .th-hero-section {
      .th-section-texts {
        left: 152px;
      }
    }

    .th-intro-video-container {
      height: 400px;
      left: 0;
      position: relative;
      width: 600px;

      .th-intro-video-wrapper {
        position: absolute;

        // transform: none;
        // top: 0;
      }
    }
  }

  @media (max-width: $break-lg) {
    .th-section {
      .th-section-title {
        font-size: 30px;
      }

      .th-section-description {
        font-size: 16px;
      }
    }

    .th-hero-section {
      .th-section-content {
        height: 350px;
      }

      .th-title {
        font-size: 42px;
        width: 300px;
      }
    }

    .th-info-section {
      .th-section-content {
        align-items: center;
      }

      .th-section-img {
        width: 320px;
      }

      .th-section-texts {
        margin-left: 60px;
        width: 320px;
      }
    }

    .th-explore-link {
      margin-top: 20px;
    }

    .th-section.th-analytics-section {
      .th-analytics-metrics {
        column-count: 3;
        padding: 42px 60px;
      }
    }

    .th-section.th-testimonial-section {
      flex-direction: column;
      padding: 0 0 60px;

      .th-section-title {
        font-size: 38px;
        font-weight: 300;
        letter-spacing: -0.23px;
      }

      .th-testimonial {
        padding: 0 20px;

        .th-text {
          font-size: 16px;
          margin-bottom: 0;
        }

        .th-person {
          @include th-flex-row-center;

          .th-avatar {
            height: 60px;
            margin-right: 24px;
            width: 60px;
          }

          .th-name {
            font-size: 14px;
          }

          .th-job {
            font-size: 12px;
          }
        }
      }
    }

    .th-section.th-inman-testimonial-section {
      .th-testimonial-text {
        font-size: 16px;
      }
    }

    .th-sitemap-section {
      .th-links {
        ul {
          columns: 3;
        }
      }
    }
  }

  @media (max-width: $break-md) {
    .th-section {
      .th-section-title {
        font-size: 30px;
      }

      .th-section-description {
        font-size: 16px;
      }

      .th-section-img {
        margin-bottom: 30px;
        margin-top: 100px;
      }
    }

    .th-section.th-main-value-prop-section {
      .th-section-title {
        font-size: 30px;
      }

      .th-section-description {
        font-size: 16px;
        width: 90%;
      }
    }

    .th-intro-video-container {
      height: unset;
      padding-bottom: 56.25%;
      width: 90%;
    }

    .th-section.th-explorer-section-test {
      .th-section-img {
        margin: 0;
      }
    }

    .th-section.th-analytics-section {
      .th-section-title {
        font-size: 30px;
      }

      .th-section-description {
        font-size: 16px;
      }

      .th-analytics-metrics {
        column-count: 2;
        padding: 42px 40px;
      }
    }

    .th-info-section {
      .th-section-content {
        align-items: center;
        flex-direction: column;
      }

      .th-section-title {
        line-height: 1.3;
        margin-bottom: 30px;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .th-section-texts {
        align-items: center;
        margin: 0;
        text-align: center;
        width: 400px;
      }
    }

    .th-section.th-main-features-section {
      .th-section-title {
        font-size: 30px;
      }
    }

    .th-section.th-company-section {
      .th-section-title {
        font-size: 21px;
      }

      .th-section-content {
        .th-company-logos {
          margin: 0;
          padding: 12px 0;
        }
      }
    }

    .th-sitemap-section {
      .th-links {
        ul {
          columns: 2;
        }
      }
    }
  }

  @media (max-width: $break-sm) {
    .th-section {
      .th-section-title {
        font-size: 27px;
      }

      .th-section-description {
        font-size: 16px;
      }
    }

    .th-hero-section {
      .th-section-content {
        height: 450px;
      }

      .th-section-texts {
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .th-title {
        font-size: 42px;
        margin-bottom: 30px;
      }
    }

    .th-info-section {
      .th-section-title {
        margin-bottom: 20px;
        width: 280px;
      }

      .th-section-texts {
        width: 100%;
      }
    }

    .th-section.th-explorer-section-test {
      padding: 55px 0;

      .th-section-texts {
        text-align: center;
        width: 100%;
      }

      .th-academy-button {
        margin: 25px auto 0;
      }
    }

    .th-section.th-start-trial-section {
      padding: 40px 30px;

      p {
        font-size: 24px;
      }

      .th-get-started {
        border: 1px solid white;
        font-size: 14px;
        font-weight: normal;
        height: 42px;
        margin-top: 20px;
        width: 120px;
      }
    }

    .th-section.th-analytics-section {
      .th-analytics-metrics {
        column-count: auto;
      }
    }

    .th-section.th-testimonial-section {
      .th-section-title {
        font-size: 30px;
        margin-bottom: 24px;
      }
    }

    .th-section.th-inman-testimonial-section {
      padding: 0 0 40px;
    }

    .th-sitemap-section {
      .th-section-title {
        text-align: center;

        a {
          display: block;
          margin: 12px 0 0;
        }
      }
    }
  }

  @media (max-width: $break-xs) {
    .th-sitemap-section {
      .th-links {
        ul {
          columns: 1;
        }
      }
    }
  }

  & + :global(.th-footer) {
    &,
    * {
      font-family: Poppins, sans-serif;
    }
  }
}
