.th-tooltip-wrapper {
  cursor: pointer;
  pointer-events: auto;
}

.th-tooltip-trigger {
  cursor: pointer;

  &.th-underline {
    border-bottom: 1px dotted #858585;
  }
}

$background-color: rgb(white, 0.95);

.th-tooltip-overlay {
  background: $background-color !important;
  border-radius: 0.75em !important;
  box-shadow: 0 4px 15px 0 rgb(0 0 0 / 20%);
  color: black !important;
  font-size: 12px !important;
  position: relative;
}

.th-tooltip-arrow {
  color: $background-color !important;
}
